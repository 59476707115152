.districts {
  border: 1px solid gray;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.district {
  display: flex;


  span {
    margin: 10px;
  }
}