.lpus {
  border: 1px solid gray;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.lpu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ccc;
  margin: 5px;
  text-align: left;
}